import React from "react";
import { styled } from "baseui";

export const AppLogo = ({ width = 200, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1031.58 185.6"
      width={width}
      style={style}
    >
      <g fill={"currentColor"}>
        <path
          d="M40.72,40.18h17.6v90.2q0,15,8.7,25.1t22.5,10.1q15.2,0,24-11.2a35.57,35.57,0,0,0,7.6-22.4q0-16.4-10-25.2a30.81,30.81,0,0,0-20.8-7.6,39.56,39.56,0,0,0-14,2.4,60.27,60.27,0,0,0-11,5.8V89a40.55,40.55,0,0,1,12.2-5.1,51.26,51.26,0,0,1,11.6-1.5q20.4,0,34.6,12.6,15,13.6,15,35,0,21.6-12.6,36.4-13.6,16-36.6,16-21.2,0-35-14.8a49.85,49.85,0,0,1-13.8-35.2Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M173.87,134.5l47.83-26.34a27.39,27.39,0,0,0-6.8-5.58q-6.4-3.39-15.6-3.39-14.2,0-23,10.8-7.8,10-7.8,24a32.14,32.14,0,0,0,8.6,21.9q8.6,9.7,23,9.7,14.79,0,22.19-8,6.8-6.8,9.8-22.2l17.61,2.4q0,15.4-12.6,29-15,16-38.2,16-19.4,0-34.1-14.1t-14.7-34.1q0-25,16.4-39.6a47.94,47.94,0,0,1,32.8-12.6q16.6,0,27.7,7.4t19.3,23l-64.8,36Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M261.12,164h49.6a29.4,29.4,0,0,0,7.2-1.59,7.32,7.32,0,0,0,4.2-7q0-7.56-15.2-12.54l-18.4-5.37q-14.2-4.78-20.2-10.15-7.2-6.37-7.2-17.11,0-14.13,11-20.7,8.2-5,21.8-5h41.4v16.8h-44.8q-11,0-11,8.6.6,5.8,10.4,9.6l18.6,5q17.2,4.8,24.4,12.4a25.78,25.78,0,0,1,7.2,18.4q0,14.6-11.4,21-7.6,4-19.8,4h-47.8Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M355.71,150.33V59.78h17.4v24.6h34.4v16.8h-34.4v52.6a8.34,8.34,0,0,0,3.2,6.8q3,3,9.4,3h21.8v16.8h-22.2q-15.4,0-23-9Q355.71,163.56,355.71,150.33Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M415.31,131.18q0-20.8,15.6-35.2,14.8-13.6,34-13.6a51.43,51.43,0,0,1,34,12.4q16.4,14,16.4,36.8a52.64,52.64,0,0,1-11.55,33.6q-13.55,17.2-35.06,17.2-23.71,0-38.55-14.7A48.37,48.37,0,0,1,415.31,132Zm18.4-.4q0,14,6.78,23.2,8.57,11.6,25.52,11.6,14,0,21.73-8,8.77-9.2,8.77-27.6,0-13.6-8.87-22.2t-22.43-8.6q-15.35,0-23.93,10.4A32.39,32.39,0,0,0,433.71,130.78Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M532.31,180.38v-105q0-18.6,11.4-28a31,31,0,0,1,20.2-7.2h17.2V57h-12.6q-9.6,0-14.2,4.8-3.4,3.8-4.4,10.8v11.8h28.8v16.8h-28.8v79.2Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M580.51,223.78v-17.2h7.8q11.8,0,15.8-7,2.8-5.2,2.8-19.2v-96h17.6v105.8q0,14.8-8.6,23.6-9.6,10-27.2,10Zm24.6-160.6q0-5.2,2.9-8.1a9.66,9.66,0,0,1,7.1-2.9q5.2,0,8.1,2.7t2.9,8.7a8.72,8.72,0,0,1-3.3,7.1,10.75,10.75,0,0,1-13.9.3A9.48,9.48,0,0,1,605.11,63.18Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M643.71,164h49.6a29.39,29.39,0,0,0,7.2-1.59,7.32,7.32,0,0,0,4.2-7q0-7.56-15.2-12.54l-18.4-5.37q-14.2-4.78-20.2-10.15-7.2-6.37-7.2-17.11,0-14.13,11-20.7,8.2-5,21.8-5h41.4v16.8h-44.8q-11,0-11,8.6.6,5.8,10.4,9.6l18.6,5q17.2,4.8,24.4,12.4a25.78,25.78,0,0,1,7.2,18.4q0,14.6-11.4,21-7.6,4-19.8,4h-47.8Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M742.5,169.18a10.78,10.78,0,0,1,3.3-7.9,11,11,0,0,1,8.1-3.3,13.13,13.13,0,0,1,8.3,2.9,9.93,9.93,0,0,1,3.7,8.3,11.56,11.56,0,0,1-3.6,8.8,11.84,11.84,0,0,1-8.4,3.4,10.93,10.93,0,0,1-8-3.4A11.9,11.9,0,0,1,742.5,169.18Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M786.9,131.18q0-20.8,15.6-35.2,14.8-13.6,34-13.6a51.43,51.43,0,0,1,34,12.4q16.4,14,16.4,36.8a52.64,52.64,0,0,1-11.55,33.6q-13.55,17.2-35.06,17.2-23.71,0-38.55-14.7A48.37,48.37,0,0,1,786.9,132Zm18.4-.4q0,14,6.78,23.2,8.57,11.6,25.52,11.6,14,0,21.73-8,8.77-9.2,8.77-27.6,0-13.6-8.87-22.2t-22.43-8.6q-15.35,0-23.93,10.4A32.39,32.39,0,0,0,805.3,130.78Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M902.3,84.38h16.8v11.2a25.32,25.32,0,0,1,9.2-9.2,31.28,31.28,0,0,1,15.8-4h5.4v16.8h-4.2q-13.4,0-20.4,11.4-5,8.2-5,19.4v50.4H902.3Z"
          transform="translate(-40.72 -40.18)"
        />
        <path
          d="M974.5,204.18a94.8,94.8,0,0,0,15,3.6,65.18,65.18,0,0,0,12.8,1.2q14.2,0,23.3-7.9t9.1-20.7v-46.8q0-15.6-8.7-25t-20.7-9.4q-13.8,0-22.6,7.6-10.2,8.8-10.2,26.4v3q0,11,8.26,19.8,9.47,9.6,23.57,9.6a28.79,28.79,0,0,0,11.08-2,75.29,75.29,0,0,0,12.29-5.8v18.4a61.87,61.87,0,0,1-12.4,4.6,59,59,0,0,1-6.1,1.2,46.42,46.42,0,0,1-6.3.4q-20.4,0-34-14.5a48.94,48.94,0,0,1-13.6-34.7q0-25.4,17.4-39.6a50.62,50.62,0,0,1,32.2-11.2q20,0,33.7,12.9a42.07,42.07,0,0,1,13.7,31.9v49.2q0,21.6-13.3,35.5t-36.1,13.9a93.05,93.05,0,0,1-18.3-1.6,101.32,101.32,0,0,1-14.5-4Z"
          transform="translate(-40.72 -40.18)"
        />
      </g>
    </svg>
  );
};

export const StyledAppLogo = styled(AppLogo, ({ $theme }) => {
  return {
    color: $theme.colors.primary
  };
});
